'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useRef } from 'react';

import { AutoComplete } from '@/components/AutoComplete/AutoComplete';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BlogSearchInput = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const divRef = useRef<HTMLDivElement>(null);

  const getInput = () => divRef.current?.querySelector('input');

  const changeSearch = (newSearch: string) => {
    getInput()?.blur?.();

    const query = newSearch ? `?q=${newSearch}` : '';

    router.replace(`${pathname}${query}`, {
      scroll: false,
    });
  };

  const defaultSearch = searchParams.get('q') || '';

  return (
    <div
      ref={divRef}
      className="w-full md:w-[31.25rem] rounded-full px-6 py-2 border border-[#D8D8D8] flex items-center mx-7 md:mx-0"
    >
      <AutoComplete
        placeholder="What are you looking for?"
        inverted
        classIcon="!hidden"
        searchPath={pathname}
        defaultSearch={defaultSearch}
        onEnter={(value) => changeSearch(value)}
        overrideOnEnter
        className="[&_input]:!px-3 [&_input]:!py-2 [&_input]:!border-none [&_input]:!outline-none [&_input]:!ring-0 [&_input]:md:!text-xl !flex-1 [&_input]:!shadow-none"
      />
      <FontAwesomeIcon
        icon={faSearch}
        className="size-5 text-[#667085] cursor-pointer"
        onClick={() => {
          const input = getInput();
          changeSearch(input?.value || '');
        }}
      />
    </div>
  );
};

//   <input
//     placeholder="What are you looking for?"
//     type="text"
//     className="flex-1 border-none focus:outline-none focus:border-none focus:ring-0 md:text-xl"
//     onChange={(e) => debouncedChangeSearch(e.target.value)}
//     defaultValue={searchParams.get('q') || ''}
//     ref={inputRef}
//     onKeyDown={(e) => {
//       if (e.key === 'Enter') {
//         if (getIsPending()) {
//           clearTimer();
//           changeSearch(e.currentTarget.value);
//         }

//         e.currentTarget.blur();
//       }
//     }}
//   />
