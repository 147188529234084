'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { CustomPagination, CustomPaginationProps } from '@/components/CustomPagination';

export type BlogPaginationProps = Omit<CustomPaginationProps, 'onNavigate'>;

export const BlogPagination = (props: BlogPaginationProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  return (
    <CustomPagination
      {...props}
      onNavigate={(page) => {
        const params = new URLSearchParams(searchParams);
        params.set('page', page.toString());

        router.replace(`${pathname}?${params.toString()}`, { scroll: true });
      }}
    />
  );
};
