export const getFavoritesLabel = (favorites: number, extraContent?: string) => {
  return `${favorites} Other${favorites > 1 ? 's' : ''} ${favorites > 1 ? 'have' : 'has'} favorited ${extraContent ?? ''}`;
};

export const isMobile = () => typeof window !== 'undefined' && window.innerWidth < 768;

export const convertPage = (page: unknown) => {
  if (!page || typeof page !== 'string') {
    return 1;
  }

  const parsed = parseInt(page, 10);

  if (isNaN(parsed) || parsed < 1) {
    return 1;
  }

  return parsed;
};
