export const calculateReadingTime = (htmlContent?: string) => {
  if (!htmlContent) return 0;

  const wordsPerMinute = 200; // Average reading speed in words per minute
  const secondsPerImage = 12; // Average time spent per image in seconds

  const text = htmlContent.replace(/<[^>]*>/g, ' ');
  const wordCount = text.split(/\s+/).filter((word) => word.length > 0).length;

  const imageCount = (htmlContent.match(/<img[^>]*>/g) || []).length;

  const textReadingTime = wordCount / wordsPerMinute; // in minutes
  const imageReadingTime = (imageCount * secondsPerImage) / 60; // in minutes

  const totalReadingTime = textReadingTime + imageReadingTime;

  return Math.ceil(totalReadingTime);
};
