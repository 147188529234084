import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { useIsMobile } from '@/hooks/useIsMobile';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PaginationButtonProps {
  className?: string;
  disabled?: boolean;
  page: number;
  onClick: (page: number) => void;
}

const PaginationButton = ({
  children,
  className,
  disabled,
  page,
  onClick,
}: PropsWithChildren<PaginationButtonProps>) => (
  <button
    type="button"
    onClick={() => onClick?.(page)}
    data-testid={`pagination-${page}-button`}
    className={twMerge(
      'border border-[#64748B4D] rounded-md text-[#64748B] shrink-0 py-1 md:py-1.5 px-2.5 md:px-[0.875rem] flex items-center justify-center gap-1 md:text-lg font-medium',
      disabled ? 'pointer-events-none text-[#64748B80] border-[#C1C7D199]' : 'hover:bg-gray-50',
      className,
    )}
  >
    {children}
  </button>
);

export interface CustomPaginationProps {
  totalItems: number;
  pageSize: number;
  currentPage: number;
  className?: string;
  onNavigate: (page: number) => void;
}

export const CustomPagination = ({
  currentPage,
  pageSize,
  totalItems,
  className,
  onNavigate,
}: CustomPaginationProps) => {
  const isMobile = useIsMobile(true);
  const pagesNumber = Math.ceil(totalItems / pageSize);

  if (!pagesNumber) return null;

  const maxVisibleItems = isMobile ? 5 : 7;
  const showStartDotsFrom = Math.ceil(maxVisibleItems / 2);
  const showEndDotsUntil = pagesNumber - Math.ceil(maxVisibleItems / 2) + 1;

  const hasStartDots = currentPage > showStartDotsFrom && pagesNumber > maxVisibleItems;
  const hasEndDots = currentPage < showEndDotsUntil && pagesNumber > maxVisibleItems;
  const hasLastPage = pagesNumber > 1;

  const getPageButtons = () => {
    const buttons = [];

    let maxButtons = maxVisibleItems - 1;

    if (hasLastPage) maxButtons--;
    if (hasStartDots) maxButtons--;
    if (hasEndDots) maxButtons--;

    const buttonsToShow = Math.min(pagesNumber - 1, maxButtons);

    const start = Math.min(
      Math.max(2, currentPage - buttonsToShow + Math.floor(maxVisibleItems / 2) - (hasEndDots ? 1 : 0)),
      pagesNumber - buttonsToShow,
    );

    for (let i = 0; i < buttonsToShow; i++) {
      const page = start + i;
      const isCurrent = currentPage === page;

      if (page === 1 || page === pagesNumber) continue;

      buttons.push(
        <PaginationButton
          key={page}
          page={page}
          disabled={isCurrent}
          className={twMerge(isCurrent && 'bg-[#181A2A] border-[#181A2A] text-white')}
          onClick={onNavigate}
        >
          {page}
        </PaginationButton>,
      );
    }

    return buttons;
  };

  const lastPage = pagesNumber;

  return (
    <div className="flex flex-col font-inter items-center md:items-end">
      <div className="flex flex-col gap-3">
        <div className={twMerge('flex justify-end gap-1.5 md:gap-2', className)}>
          <PaginationButton
            page={Math.max(1, currentPage - 1)}
            className="max-[409px]:hidden md:w-[6.25rem]"
            disabled={currentPage === 1}
            onClick={onNavigate}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="size-3" />
            <span data-testid="page-back">Back</span>
          </PaginationButton>

          <PaginationButton
            page={1}
            disabled={currentPage === 1}
            className={twMerge(currentPage === 1 && 'bg-[#181A2A] border-[#181A2A] text-white')}
            onClick={onNavigate}
          >
            1
          </PaginationButton>

          {hasStartDots && (
            <span className="border border-[#64748B4D] rounded-md text-[#64748B] py-1.5 px-2 md:text-lg items-center justify-center shrink-0">
              ...
            </span>
          )}

          {getPageButtons()}

          {hasEndDots && (
            <span className="border border-[#64748B4D] rounded-md text-[#64748B] py-1.5 px-2 md:text-lg items-center justify-center shrink-0">
              ...
            </span>
          )}

          {hasLastPage && (
            <PaginationButton
              page={lastPage}
              disabled={currentPage === lastPage}
              className={twMerge(currentPage === lastPage && 'bg-[#181A2A] border-[#181A2A] text-white')}
              onClick={onNavigate}
            >
              {lastPage}
            </PaginationButton>
          )}

          <PaginationButton
            page={Math.min(pagesNumber, currentPage + 1)}
            className="md:w-[6.25rem] max-[409px]:hidden"
            disabled={currentPage === pagesNumber}
            onClick={onNavigate}
          >
            <span data-testid="page-next">Next</span>
            <FontAwesomeIcon icon={faChevronRight} className="size-3" />
          </PaginationButton>
        </div>
        <div className="flex gap-6 justify-between min-[410px]:hidden">
          <PaginationButton
            page={Math.max(1, currentPage - 1)}
            className="flex-1"
            disabled={currentPage === 1}
            onClick={onNavigate}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="size-3" />
            <span data-testid="page-back">Back</span>
          </PaginationButton>
          <PaginationButton
            page={Math.min(pagesNumber, currentPage + 1)}
            className="flex-1"
            disabled={currentPage === pagesNumber}
            onClick={onNavigate}
          >
            <span data-testid="page-back">Next</span>
            <FontAwesomeIcon icon={faChevronRight} className="size-3" />
          </PaginationButton>
        </div>
      </div>

      <div className="mt-4 flex justify-center">
        <span className="text-center text-[#64748B] md:text-lg">
          Showing {Math.min(pageSize * (currentPage - 1) + 1, totalItems)}-
          {Math.min(pageSize * (currentPage - 1) + pageSize, totalItems)} of {totalItems}
        </span>
      </div>
    </div>
  );
};
