import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { LaMesaRecVanLogo } from '@lamesarv-sdk/components';

interface BlogCardProps {
  link: string;
  imageUrl?: string;
  badge?: string;
  text: string;
  helper?: string;
  className?: string;
}

export const BlogCard = ({ badge, link, text, imageUrl, helper, className }: BlogCardProps) => (
  <Link
    className={twMerge('p-4 w-full h-full flex flex-col gap-3 items-start font-inter', className)}
    href={link}
    prefetch
  >
    {imageUrl && <img className="aspect-[360/208] object-cover rounded-t-lg w-full mb-2" src={imageUrl} alt="" />}
    {!imageUrl && <LaMesaRecVanLogo className="aspect-[360/208] object-cover rounded-t-lg w-full mb-2" />}

    {badge && <span className="px-2.5 py-1 bg-blue-100 text-blue-600 font-medium text-sm rounded">{badge}</span>}
    <p className="text-black-500 text-2xl font-semibold">{text}</p>
    {helper && <span className="text-sm text-black-350">{helper}</span>}
  </Link>
);
